export default function applyLocationOffset(rect, location, isOffsetBody) {
  let {
    top,
    left
  } = rect;
  if (isOffsetBody) {
    left = 0;
    top = 0;
  }
  return {
    top: top + location.top,
    left: left + location.left,
    height: rect.height,
    width: rect.width
  };
}