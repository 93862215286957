import { zoneAndRule } from './zone-and-rule';
import { padNumber } from './pad-number';
// tslint:disable:max-line-length
/**
 * A function which returns the abbreviated name of the timezone. You can specify an optional date for returning the timezone name at a different point in time. The corresponding UTC date is used for locating the relevant rule. Timezone names change both historically and when they reflect the Daylight Savings Time rules.
 *
 * @param timezone - The timezone name. For example, `America/Chicago`, `Europe/Sofia`.
 * @param date - A date for which to locate the zone rule. By default, the current time is used.
 *
 * @return - The abbreviated name of the timezone at the specified date or, if not set, returns now.
 *
 * @example
 * ```ts-no-run
 * import { abbrTimezone } from '@progress/kendo-date-math';
 * import '@progress/kendo-date-math/tz/Europe/Sofia';
 *
 * const dstDate = new Date('2018-04-01T00:00:00Z');
 * console.log(abbrTimezone('Europe/Sofia', dstDate)); // EEST
 *
 * const date = new Date('2018-01-01T00:00:00Z');
 * console.log(abbrTimezone('Europe/Sofia', date)); // EET
 * ```
 */
// tslint:enable:max-line-length
export const abbrTimezone = (timezone, date = new Date()) => {
  if (timezone === "Etc/UTC") {
    return 'UTC';
  }
  if (timezone === "Etc/GMT") {
    return 'GMT';
  }
  if (timezone === '') {
    return '';
  }
  const {
    zone,
    rule
  } = zoneAndRule(timezone, date);
  const base = zone[2];
  if (base.indexOf("/") >= 0) {
    return base.split("/")[rule && +rule[6] ? 1 : 0];
  } else if (base.indexOf("%s") >= 0) {
    return base.replace("%s", !rule || rule[7] === "-" ? '' : rule[7]);
  } else if (base.indexOf("%z") >= 0) {
    const hours = -1 * Math.trunc(zone[0] / 60);
    const hoursPart = padNumber(hours, 2);
    const signPart = hours > 0 ? '+' : '';
    const minutes = Math.abs(Math.trunc(zone[0] % 60));
    const minutesPart = minutes === 0 ? '' : padNumber(minutes, 2);
    return base.replace("%z", `${signPart}${hoursPart}${minutesPart}`);
  }
  return base;
};