export const JcmsUIConstants: any = {
  kendoDateFormat: {
    displayFormat: 'dd-MM-yyyy',
    inputFormat: 'dd-MM-yyyy'
  },

  dataManagerTypes: {
    signatureTitle: 'signaturetitle',
    tag: 'tag',
    room: 'room',
    organ: 'organ',
    ngo: 'ngo',
    contacts: 'contacts',
    function: 'function',
    gasession: 'gasession',
    theme: 'theme',
    agenda: 'agenda',
    country: 'country',
    statementLabel: 'statementlabel',
    banners: 'banners',
    location: 'location',
    timeZone: 'timezone',
    specialcharacters: 'specialcharacters'
  },

  langMapping: {
    "English": "en",
    "French": "fr",
    "Arabic": "ar",
    "Chinese": "zh",
    "Russian": "ru",
    "Spanish": "es",
    "Edit All": "al"
  },

  langCharMapping: {
    "en": "English",
    "fr": "Français",
    "ar": "العربية",
    "zh": "中文",
    "ru": "Pусский",
    "es": "Español",
    "al": "Français"
  },

  langFullMapping: {
    "en": "English",
    "fr": "French",
    "ar": "Arabic",
    "zh": "Chinese",
    "ru": "Russian",
    "es": "Spanish",
    "al": "French"
  },

  langLabels: {
    "en": "English",
    "fr": "French",
    "ar": "Arabic",
    "zh": "Chinese",
    "ru": "Russian",
    "es": "Spanish",
    "de": "German",
    "ot": "Other",
    "m": "Multilingual",
    "oth-hi": "Other - Hindi",
    "oth-de": "Other - German",
    "oth-fa": "Other - Persian",
    "oth-pl": "Other - Portuguese",
    "oth-sw": "Other - Kiswahili"
  },

  templateDbToTxt: {
    "agenda": "agenda",
    "program": "details"
  },

  kendoEditorPasteCleanupSettings: {
    convertMsLists: true,
    removeHtmlComments: true,
    stripTags: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    // removeAttributes: ['lang'],
    removeMsClasses: true,
    removeMsStyles: true,
    removeInvalidHTML: true
  },

  kendoDropDownVirtualizationSettings: { pageSize: 10, itemHeight: 28 },

  allLanguge: ["en", "fr", "ar", "zh", "ru", "es"],

  acefrs: ["ar", "zh", "en", "fr", "ru", "es"],

  tranStatusLangs: ["ar", "zh", "fr", "ru", "es"],

  englishWarningText: "Modifying this english value will impact the translation(s) applicable for this text",

  noTransAvailText: "No translation(s) available",

  tranClass: { false: 'badge badge-danger', true: 'badge badge-success' },

  nbComponentStatus: {
    basic: 'basic',
    primary: 'primary',
    success: 'success',
    warning: 'warning',
    danger: 'danger',
    info: 'info',
    control: 'control'
  },

  nbIcons: {
    done_all_outline: 'done-all-outline',
    close_outline: 'close-outline'
  },

  userRoles: {
    JcmsAdmin: 'JCMS Admin',
    JournalTechnicalAdmin: 'Journal Technical Admin',
    JournalSubmitter: 'Journal Submitter',
    JournalInformalsSubmitter: 'Journal Informals Submitter',
    JournalOthersSubmitter: 'Journal Others Submitter',
    SideEventSubmitter: 'Side Event Submitter',
    JournalTreatyAdmin: 'Journal Treaty Admin',
    JournalDocumentAdmin: 'Journal Document Admin',
    JournalDataManager: 'Journal DataManager',
    JournalMSASubmitter: 'Journal MSA Submitter',
    eStatementSubmitter: 'eStatement Submitter',
    GeneralInformationManager: 'General Information Manager',
    JournalOrganImporter: 'Journal Organ Importer',
    eStatementsReadonly: 'eStatements Readonly',
    Journal_eStatementSubmitter: 'Journal-eStatement Submitter',
    eStatementDataManager: 'eStatement DataManager',
    Journal_Function_Manager: 'Journal Function Manager',
    Journal_Person_Manager: 'Journal Person Manager',
    Journal_City_Manager: 'Journal City Manager',
    Journal_Organ_Data_Entry_Manager: 'Journal Organ Data Entry',
    Journal_Grouping_Template_Manager: 'Journal Grouping Template Manager',
    Journal_IGO_NGO_Manager: "Journal IGO NGO Manager",
    Journal_PDF_Manager: "Journal PDF Manager",
    Journal_ICJ_Submitter: "Journal ICJ Submitter",
    Journal_Lookups_Manager: "Journal Lookups Manager",
    Journal_Organ_Data_Update: "Journal Organ Data Update",
    Journal_Reports_Manager : 'Journal Reports Manager'
  },

  crudActions: {
    new: 'new',
    edit: 'edit',
    clone: 'clone',
    delete: 'delete',
    view: 'view'
  },

  docStatusList: {
    0: { color: "warning", name: "Not Multilingual" },
    1: { color: "success", name: "Manual" },
    2: { color: "info", name: "Imported" },
    3: { color: "error", name: "Needs Tagging" },
    4: { color: "tertiary", name: "Not available on ODS" },
  },

  docStatusArr: [0, 1, 2, 3, 4],

  organPlaceholderTxt: { listPage: "Search organization name...", addPage: "Search added organization..." },

  unDocsUrl: "https://undocs.org/",

  genderList: ['Masculine', 'Feminine', 'NA'],

  infoBaseUrl: "https://unitednations.sharepoint.com/sites/Journal/SitePages/Journal-Business-Rules.aspx",

  infoUrlList: {
    dailyList: '#logic-for-the-daily-list-of-documents',
    signatures: '#signatures-ratifications',
    tagImports: '#logic-for-the-daily-list-of-documents',
    agendaCards: '#agenda-cards',
  },

  infoUrlText: "For business logic of [PAGE], please visit this",

  filterSettings: {
    caseSensitive: false,
    operator: "contains",
  },

  meetingTypes: [
    { text: "Unknown", value: 0 },
    { text: "Official", value: 1 },
    { text: "Informal", value: 2 },
    { text: "Other", value: 3 },
    { text: "PressConference", value: 4 },
    { text: "OfficialAnnouncement", value: 5 },
    { text: "InformalAnnouncement", value: 6 },
    { text: "OtherAnnouncement", value: 7 },
    { text: "Deleted", value: 8 },
    { text: "UnPublished", value: 9 }
  ],

  defaultLocation: "New York",
  conferencesDefaultLocation : "UNHQ",

  dateMinMax: {
    // use below fields for kendo date input
    min: new Date(2018, 0, 1, 0, 0, 0, 0), // month index starts from 0, 0-Jan and 11-Dec
    max: new Date(2100, 11, 31, 23, 59, 59, 0),

    validationMessage: "Only values between 01-01-2018 and 31-12-2100 are valid",

    // use below values for Reactive/Template form validations
    minUTC: new Date('2018-01-01T00:00:00.000Z'),
    maxUTC: new Date('2100-12-31T00:00:00.000Z')
  },

  gDocDateRange: 7,

  textSearchMaxLength: 250, // 250 characters

  emailConfigKeys: ["to", "cc", "bcc"],

  yesNoOptions: [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ],

  estatementLaunchDate : new Date(2020,7,1,0,0,0), // 2020-08-01
  estatementAccessedLocationWiseDate: new Date(2024,6,1,0,0,0), // 2024-07-01(Implementation of location in estatement Url )
  agendaCardImportDate: new Date(2022,5,1,0,0,0)  ,//2022-06-21
  icjStartDate : new Date(2023,3,1,0,0,0), //2023-04-01
  summaryStartDate : new Date(2020,2, 24),//2020-03-25
  defaultStartDate: new Date((new Date().getTime() - 30 * 24 * 60 * 60 * 1000)),
  allOption: 'ALL',
  conferencesStartDate : new Date(2022,5,2,0,0,0) // 2022-06-02

}
